import React from 'react';
import { withRouter } from 'react-router-dom'
import {withApollo} from 'react-apollo'
import {withStyles, Link, Avatar, InputBase, Button, IconButton, InputAdornment, Tooltip, Grid} from '@material-ui/core';
import {VerifiedUser, Visibility, VisibilityOff } from '@material-ui/icons';
import session from '../../../model/session'
import PinInput from 'react-pin-input';
import {VERIFIED_BY_ID} from '../../../api/graphql-mutation'

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        overflowY:"auto",
        background:"#F2F2F2",
        fontFamily:"arial",
    },

    box:{
        margin:"auto",
        height:"90%"
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#D1D1D1",
        color:"#FFFFFF",
    },

    colorButton:{
        background:"#FDAF2A",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
    }
}
class Login extends React.Component {
    constructor(props) { //holds data to be parsed into the page
        super(props);
        this.state = {
            users: [],

            pin: "",
            pinComplete: false,
        };
    }
    
    render() {
        const {
            classes, 
            handleChange, 
            handleSignIn, 
            emailValue, 
            passwordValue, 
            error, 
            redirectProps, 
            skipPassword, 
            joining,
            type,
            jbIndex,
            selectedJB
        } = this.props

        return (
            <div className={classes.container}>
                {this.props.verification ? 
                <Grid style={{textAlign:"center"}} direction="column" justify="center" alignItems="center" spacing={2} container className={classes.box}>
                    <Grid item>
                        <Avatar className={classes.avatar} src={require('../../../element/registerlady.png')}></Avatar>                            
                    </Grid>
                    <Grid item className={classes.header}>
                        You're almost signed up
                    </Grid>
                    <p >Enter the code we sent to <br/><strong>{this.props.user.email}</strong><br/> to finish signing up</p>
                    <Grid item>
                        <form  onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                            <PinInput
                                length={6}
                                initialValue=""
                                onChange={(value) => {if(value.length < 6){this.setState({pinComplete:false})} this.setState({pin:value})}} // Handles changes to the PIN
                                onComplete={()=>{this.setState({pinComplete: true})}} // Called when all 6 digits are entered
                                type="numeric" // Restricts input to numeric values
                                inputMode="numeric"
                                style={{ padding: '10px' }}
                                inputStyle={{
                                borderColor: 'lightgray',
                                borderWidth: '2px',
                                borderRadius: '5px',
                                backgroundColor: 'white',
                                fontSize: '20px',
                                width: '40px',
                                height: '50px',
                                margin: '0 5px',
                                }}
                                inputFocusStyle={{
                                borderColor: 'blue',
                                }}
                                autoSelect={true} // Automatically selects the input field on focus
                            />
                            <div>
                            <br/>
                            <Button 
                                type="submit" 
                                id="button" 
                                className={classes.buttons}
                                style = {!this.state.pinComplete ? {color:"white", background:"#D1D1D1"} : {color:"white", background:"#FDAF2A"}}
                                disabled = {!this.state.pinComplete}
                                onClick={()=>{
                                    if(this.props.user.verification === parseInt(this.state.pin)){
                                        const redirectProps = this.props?.location?.state?.redirectProps
                                        const skipPassword = this.props?.location?.state?.skipPassword
                                        const joining = this.props?.location?.state?.joining
                                        const type = this.props?.location?.state?.type
                                        const jbIndex = this.props?.location?.state?.jbIndex
                                        const selectedJB = this.props?.location?.state?.selectedJB


                                            this.props.client.mutate({
                                                mutation: VERIFIED_BY_ID,
                                                variables: {
                                                    _id: this.props.user._id,
                                                    verified: true,
                                                },
                                            }).then((result)=>{
                                                // console.log(result)
                                                session.setUser(this.props.user)
                                                this.props.history.push({
                                                    pathname: redirectProps ? redirectProps : "/",
                                                    state:{
                                                        type: type,
                                                        jbIndex: jbIndex,
                                                        selectedJB: selectedJB,
                                                        redirectProps: redirectProps,
                                                        skipPassword: skipPassword,
                                                        joining: joining,
                                                    }
                                                })
                                            })

                                            
                                    }else{
                                        console.log("WRONG")
                                    }
                                }}
                            >
                                CONTINUE
                            </Button>  
                            </div>
                            

                            <p style={{textAlign:"center", marginTop:"10px", fontSize:"0.8rem"}}>
                                Didn't get the code?
                                <Link style={{cursor:"pointer", marginLeft:"5px", fontWeight:"bold", color:"#E97610"}}>
                                    Resend code
                                </Link>
                            </p>     
                        </form>
                    </Grid>
                </Grid>  
                :
                <Grid container  justify="center" alignItems="center" className={classes.box}>
                    <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../../../element/loginMan.png')}></Avatar>                            
                        </Grid>
                        <Grid item className={classes.header}>
                            <span role="img" aria-label="hello">👋</span> Well, hello there!
                        </Grid>
                        <Grid item>
                            {error && 
                            <p style={{color:"#E97610", textAlign:"center", margin:"0"}}>
                                Your email or password is incorrect.
                            </p> }
                        </Grid>
                        <Grid item>
                            <form  onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                                <InputBase className={classes.field}
                                    autoFocus={true}
                                    value={emailValue}
                                    name="email"
                                    placeholder="your email address"
                                    onChange={(e)=>{handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                />
                                <InputBase className={classes.field}
                                    value={passwordValue}
                                    name="password"
                                    type= {this.state.revealPassword ? "text" : "password"}
                                    placeholder="your password"
                                    onChange={(e)=>{handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                    endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.togglePassword}
                                            edge="end"
                                            style={{
                                                marginTop: "-2rem",
                                                marginLeft: "180px",
                                                backgroundColor: 'transparent',
                                                borderColor: 'transparent',
                                                outline: 'none'
                                            }}
                                          >
                                            <Tooltip 
                                                title={this.state.revealPassword ? "Hide Password" : "Show Password"} 
                                                enterDelay={700}
                                            >
                                            {this.state.revealPassword ? 
                                                <VisibilityOff fontSize="small"/> 
                                                :
                                                <Visibility fontSize="small"/>
                                            }
                                            </Tooltip>
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                />
                                <p style={{textAlign:"center", fontSize:"0.8rem"}}>
                                    <Link href="recovery" style={{cursor:"pointer", fontWeight:"bold", textDecoration:"underline", color:"#E97610"}}>
                                        Forgot your password?
                                    </Link>
                                </p>
                                
                                <Button 
                                    type="submit" 
                                    onClick={()=>{handleSignIn()}} 
                                    id="button" 
                                    className={classes.buttons}
                                    style = {(passwordValue ==="" || emailValue === "") ? {color:"white", background:"#D1D1D1"} : {color:"white", background:"#FDAF2A"}}
                                    disabled = {(passwordValue ==="" || emailValue === "" )? true:false}
                                >
                                    LOG IN 
                                </Button>  

                                <p style={{textAlign:"center", marginTop:"10px", fontSize:"0.8rem"}}>
                                    Need an account? 
                                    <Link 
                                        onClick={() =>{
                                            this.props.history.push({
                                                pathname:"/register",
                                                state:{
                                                    type: type,
                                                    jbIndex: jbIndex,
                                                    selectedJB: selectedJB,
                                                    redirectProps: redirectProps,
                                                    skipPassword: skipPassword,
                                                    joining: joining,
                                                }
                                            })
                                        }} 
                                        style={{
                                            cursor:"pointer", 
                                            marginLeft:"5px", 
                                            fontWeight:"bold", 
                                            color:"#E97610"
                                        }}
                                    >
                                        Register
                                    </Link>
                                </p>     
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </div>
        );
        
    }

    togglePassword = () =>{
        this.setState(prev =>({
            revealPassword: !prev.revealPassword
        }));
    }
}

export default withApollo(withRouter(withStyles(styles)(Login)))