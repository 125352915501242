import React from 'react';
import {withStyles, Link, Avatar, InputBase, Button, Grid, Modal} from '@material-ui/core';
import {withApollo} from 'react-apollo'
import { withRouter } from 'react-router-dom'

import GameTopPanel from './gameTopPanel'
import session from '../../../model/session'
import {CREATE_USER,VERIFIED_BY_ID} from '../../../api/graphql-mutation'
import {emailChecker} from '../../../utility/function'
import PinInput from 'react-pin-input';

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        overflowY:"auto",
        overflowX:"hidden",
        background:"#F2F2F2",
        fontFamily:"arial",
    },

    box:{
        margin:"auto",
        height:"90%"
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#D1D1D1",
        color:"#FFFFFF",
    },

    colorButton:{
        background:"#FDAF2A",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
    },
    modal:{
        padding:"40px", 
        borderRadius:"5px", 
        width:"300px", 
        margin:"auto", 
        marginTop:"15%", 
        background:"white",
        border:'none',
        outline:'none'
    },
    modalHeader:{
        color:"#4F4F4F", 
        fontSize:"14px", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center",
        fontWeight:"bold"
    },
    modalText:{
        color:"#4F4F4F", 
        fontSize:"0.8rem", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center"
    }
}
class Registration extends React.Component {
    constructor(props) { //holds data to be parsed into the page
        super(props);
        this.state = {
            nickname: "",
            email: "",
            password:"",
            cPassword: "",

            emailError: false,
            passwordError: false,

            existedEmail: false,
            open: false,

            userTBV: null,
            pin: "",
            pinComplete: false,
        };
    }
    
    render() {
        const {classes} = this.props
        const {nickname, email, password, cPassword, emailError, passwordError,existedEmail,open} = this.state
        const hasValue = nickname !== "" && email !== "" && password !== "" && cPassword !== ""
        // console.log(this.state.userTBV)
        return (
            <div className={classes.container}>
                <GameTopPanel reqLogin={false}/>
                <Grid style={{textAlign:"center"}} container  justify="center" alignItems="center" className={classes.box}>
                    {this.state.userTBV ? 
                    <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../../../element/registerlady.png')}></Avatar>                            
                        </Grid>
                        <Grid item className={classes.header}>
                            You're almost signed up
                        </Grid>
                        <p >Enter the code we sent to <br/><strong>{this.state.userTBV.email}</strong><br/> to finish signing up</p>
                        <Grid item>
                            <form  onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    onChange={(value) => {if(value.length < 6){this.setState({pinComplete:false})} this.setState({pin:value})}} // Handles changes to the PIN
                                    onComplete={()=>{this.setState({pinComplete: true})}} // Called when all 6 digits are entered
                                    type="numeric" // Restricts input to numeric values
                                    inputMode="numeric"
                                    style={{ padding: '10px' }}
                                    inputStyle={{
                                    borderColor: 'lightgray',
                                    borderWidth: '2px',
                                    borderRadius: '5px',
                                    backgroundColor: 'white',
                                    fontSize: '20px',
                                    width: '40px',
                                    height: '50px',
                                    margin: '0 5px',
                                    }}
                                    inputFocusStyle={{
                                    borderColor: 'blue',
                                    }}
                                    autoSelect={true} // Automatically selects the input field on focus
                                />
                                <div>
                                <br/>
                                <Button 
                                    type="submit" 
                                    id="button" 
                                    className={classes.buttons}
                                    style = {!this.state.pinComplete ? {color:"white", background:"#D1D1D1"} : {color:"white", background:"#FDAF2A"}}
                                    disabled = {!this.state.pinComplete}
                                    onClick={()=>{
                                        if(this.state.userTBV.verification === parseInt(this.state.pin)){
                                            const redirectProps = this.props?.location?.state?.redirectProps
                                            const skipPassword = this.props?.location?.state?.skipPassword
                                            const joining = this.props?.location?.state?.joining
                                            const type = this.props?.location?.state?.type
                                            const jbIndex = this.props?.location?.state?.jbIndex
                                            const selectedJB = this.props?.location?.state?.selectedJB

                                                //session.setUser(this.state.userTBV)

                                                this.props.client.mutate({
                                                    mutation: VERIFIED_BY_ID,
                                                    variables: {
                                                        _id: this.state.userTBV._id,
                                                        verified: true,
                                                    },
                                                }).then((result)=>{
                                                    session.setUser(this.state.userTBV)
                                                    this.props.history.push({
                                                        pathname: redirectProps ? redirectProps : "/",
                                                        state:{
                                                            type: type,
                                                            jbIndex: jbIndex,
                                                            selectedJB: selectedJB,
                                                            redirectProps: redirectProps,
                                                            skipPassword: skipPassword,
                                                            joining: joining,
                                                        }
                                                    })
                                                })
                                                
                                        }else{
                                            console.log("WRONG")
                                        }
                                    }}
                                >
                                    CONTINUE
                                </Button>  
                                </div>
                               

                                <p style={{textAlign:"center", marginTop:"10px", fontSize:"0.8rem"}}>
                                    Didn't get the code?
                                    <Link style={{cursor:"pointer", marginLeft:"5px", fontWeight:"bold", color:"#E97610"}}>
                                        Resend code
                                    </Link>
                                </p>     
                            </form>
                        </Grid>
                    </Grid>    
                     
                    :
                    <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../../../element/registerlady.png')}></Avatar>                            
                        </Grid>
                        <Grid item className={classes.header}>
                            Create an account
                        </Grid>
                        <Grid item>
                            {emailError && 
                            <p style={{color:"#E97610", textAlign:"center", margin:"0"}}>
                                Please input a valid email
                            </p> }
                            {passwordError && 
                            <p style={{color:"#E97610", textAlign:"center", margin:"0"}}>
                                Please make sure both of your passwords match
                            </p> }
                            {existedEmail &&
                            <p style={{color:"#e97610",textAlign:"center", margin:"0"}}>
                                ⚠️ Email ID already exists. Please log in.
                            </p>
                            }
                        </Grid>
                        <Grid item>
                            <form  onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                                <InputBase className={classes.field}
                                    autoFocus
                                    value={nickname}
                                    name="nickname"
                                    placeholder="your nickname"
                                    onChange={(e)=>{this.handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                />
                                <InputBase className={classes.field}
                                    autoFocus
                                    value={email}
                                    name="email"
                                    placeholder="your email address"
                                    onClick={() => emailError && this.setState({emailError: false})}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                />
                                <InputBase className={classes.field}
                                    autoFocus
                                    value={password}
                                    name="password"
                                    type= "password"
                                    placeholder="your password"
                                    onClick={() => passwordError && this.setState({passwordError: false})}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                    
                                />
                                <InputBase className={classes.field}
                                    autoFocus
                                    value={cPassword}
                                    name="cPassword"
                                    type= "password"
                                    placeholder="confirm your password"
                                    onClick={() => passwordError && this.setState({passwordError: false})}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                    
                                />
                                <Button 
                                    type="submit" 
                                    id="button" 
                                    className={classes.buttons}
                                    style = {!hasValue ? {color:"white", background:"#D1D1D1"} : {color:"white", background:"#FDAF2A"}}
                                    disabled = {!hasValue}
                                    onClick={()=>this.createUser()}
                                >
                                    REGISTER
                                </Button>  

                                <p style={{textAlign:"center", marginTop:"10px", fontSize:"0.8rem"}}>
                                    Already have an account? 
                                    <Link href='/login' style={{cursor:"pointer", marginLeft:"5px", fontWeight:"bold", color:"#E97610"}}>
                                        Login
                                    </Link>
                                </p>     
                            </form>
                        </Grid>
                    </Grid>    
                    
                    }

                </Grid>
                <Modal open={false} onClose={this.closePopUp} aria-labelledby="Email Existed Error">
                    <div className={classes.modal}>
                        <p className={classes.modalHeader}>
                            Email ID already exists. Please log in.
                        </p>                        
                        <Button 
                            type="submit" 
                            onClick={() =>{
                                this.closePopUp()
                                !existedEmail && this.props.history.push({pathname:'/login'});
                            }}
                            className={classes.buttons}
                            style = {{
                                background:"#FDAF2A",
                                width: "100%",
                            }}
                        >
                            OKAY, GOT IT
                        </Button>  
                    </div>

                </Modal>
            </div>
        );   
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    // New User
    createUser = () => {
        const {nickname, email, password, cPassword} = this.state

        const redirectProps = this.props?.location?.state?.redirectProps
        const skipPassword = this.props?.location?.state?.skipPassword
        const joining = this.props?.location?.state?.joining
        const type = this.props?.location?.state?.type
        const jbIndex = this.props?.location?.state?.jbIndex
        const selectedJB = this.props?.location?.state?.selectedJB


        this.setState({
            passwordError: (password !== cPassword),
            emailError: !emailChecker(email)
        }, () =>{
            if(this.state.passwordError || this.state.emailError){
                return
            }

            this.props.client.mutate({
                mutation: CREATE_USER,
                variables: {
                    name: nickname,
                    email: email,
                    password: password
                },
            }).then((result)=>{
                // console.log(result)
                if(result.data.userCreateOne){
                    let currentUser = result.data.userCreateOne
                    // session.setUser(currentUser)
                    // this.props.history.push({
                    //     pathname: redirectProps ? redirectProps : "/",
                    //     state:{
                    //         type: type,
                    //         jbIndex: jbIndex,
                    //         selectedJB: selectedJB,
                    //         redirectProps: redirectProps,
                    //         skipPassword: skipPassword,
                    //         joining: joining,
                    //     }
                    // })
                    this.setState({
                        userTBV: currentUser
                    })
                }else{
                    this.setState({
                        existedEmail: true
                    })
                }

            })
        });
    }

    closePopUp = () =>{
        this.setState({
            open: false
        })
    }
}

export default withApollo(withRouter(withStyles(styles)(Registration)))